import { get, post } from "../helpers/apiHelper";

const LOGGER_ENDPOINT = process.env.REACT_APP_BASE_PATH_LOGGER;

export async function insert(data, accountId) {
  return post(`${LOGGER_ENDPOINT}/${accountId}/insert`, { message: data });
}

export async function filter(accountId) {
  return get(`${LOGGER_ENDPOINT}/${accountId}/filter`);
}

export async function download(accountId) {
  window.open(`${LOGGER_ENDPOINT}/${accountId}/download`);
}

import React from "react";
import { Redirect } from "react-router-dom";
import { observer } from "mobx-react";

import { toFileSize } from "../../../services/misc";
import { exchangeItem } from "../../../controllers/ExchangeController";

import { Button, Grid, Header, Icon, Loader } from "semantic-ui-react";

export default observer(
  class ExchangeItem extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        accountId: localStorage.getItem("accountId"),
        loading: true,
        payload: null
      };
    }

    componentDidMount() {
      if (this.state.loading) this.load();
    }

    back = () => {
      window.history.back(-1);
    };

    load = () => {
      const id = this.props.match.params.number;
      exchangeItem(this.state.accountId, id).then(d => {
        this.setState({ payload: d.data.payload, loading: !d.data.success });
      });
    };

    renderErrors(data) {
      return data.map(function(item, key) {
        return <p key={key}>{item.errorMessage}</p>;
      });
    }

    render() {
      const { loading, payload } = this.state;

      if (
        payload != null &&
        payload.exchange != null &&
        payload.exchange[0] == null
      ) {
        return <Redirect to="/exchange" />;
      }

      return (
        <div>
          {loading === true ? (
            <Loader active />
          ) : (
            <Grid columns={2} style={{ padding: "40px 24px" }}>
              <Grid.Row>
                <Grid.Column computer={3} widescreen={2}>
                  <Button.Group>
                    <Button onClick={this.back}>
                      <Icon name="angle left" /> Back
                    </Button>
                  </Button.Group>
                </Grid.Column>
                <Grid.Column computer={13} widescreen={14}>
                  <Header as="h2" className="clear-top">
                    <Icon name="settings" />
                    <Header.Content>
                      TRANSFER REQUEST
                      <Header.Subheader>
                        Data sync processing detail.
                      </Header.Subheader>
                    </Header.Content>
                  </Header>
                  <hr />
                  <h4>Transfer Request</h4>
                  <p>Identifier: {payload.exchange[0].id}</p>
                  <p>
                    Uploaded:{" "}
                    {new Date(payload.exchange[0].createDate).toLocaleString(
                      "en-GB"
                    )}
                  </p>
                  <p>Description: {payload.exchange[0].descriptor}</p>
                  <h5>AWS S3</h5>
                  <p>Bucket Name: {payload.exchange[0].bucketName}</p>
                  <p>ETag: {payload.exchange[0].eTag}</p>
                  <p>Key: {payload.exchange[0].key}</p>
                  <p>File Size: {toFileSize(payload.exchange[0].size)}</p>
                  <h5>SQL Transform</h5>
                  <p>
                    Row Count: {payload.exchange[0].rowCount.toLocaleString()}
                  </p>
                  <p>Template: {payload.exchange[0].clientItem.name}</p>
                  <h5>State</h5>
                  <p>
                    <Icon
                      name={
                        payload.exchange[0].exchangeState.id < 0
                          ? "attention"
                          : "checkmark"
                      }
                    />
                    Code: {payload.exchange[0].exchangeState.id},{" "}
                    {payload.exchange[0].exchangeState.descriptor}
                  </p>
                  {payload.errors.length > 0 ? (
                    <div>{this.renderErrors(payload.errors)}</div>
                  ) : (
                    <br />
                  )}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          )}
        </div>
      );
    }
  }
);

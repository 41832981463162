import React from "react";
import { observer } from "mobx-react";

import { typeFilter } from "../../../controllers/ExchangeController";

import { Card, Container, Header, Image, Loader } from "semantic-ui-react";

export default observer(
  class TicketList extends React.Component {
    state = {
      accountId: localStorage.getItem("accountId"),
      items: [],
      loading: true,
      params: this.props.match.params
    };

    componentDidMount() {
      if (this.state.loading) this.load();
    }

    load = () => {
      const { params } = this.state;

      typeFilter(params.account, null)
        .then(r => {
          let d = r.data;

          d = d
            .filter(q => q.jsonSchema != null)
            .map(p => {
              return {
                header: p.name,
                color: p.relayToFreshdesk ? "purple" : "blue",
                description: p.description,
                href: "/eform/" + params.account + "/" + p.id
              };
            });

          this.setState({
            items: d,
            loading: false
          });
        })
        .catch(() => this.setState({ items: [] }));
    };

    render() {
      const { items, loading, params } = this.state;
      console.log(items);

      return (
        <div>
          {loading === true ? (
            <Loader active />
          ) : (
            <div>
              <Container className="eform-container" style={{ width: "94%" }}>
                <Header as="div">
                  <Image
                    src={`/custom-content/${params.account}_logo.png`}
                    size="massive"
                    verticalAlign="middle"
                  />
                  <Header.Content as="h1" className="">
                    Welcome
                  </Header.Content>
                </Header>
                <p>
                  Mandatory questions are marked with a
                  red asterisk (*) or 'Required' text. If you have missed any
                  mandatory questions you will be notified when you attempt to
                  submit the form.
                </p>
                <h3>Forms ({items.length})</h3>
                <Card.Group itemsPerRow={3} items={items} />
              </Container>
            </div>
          )}
        </div>
      );
    }
  }
);

import React from "react";
import Form from "react-jsonschema-form";
import {priceFieldOverwrite, formDataOverwrite, dateFieldOverwrite} from "containers/product/forms/formsHelpers";

export default class JsonSchemaForm extends React.Component {
    errorTemplate() {
        return (
            <div className="alert alert-danger" role="alert">
                <h4 className="alert-heading">Error</h4>
                <p style={{color: '#912d2b'}}>Please complete the missing mandatory fields below before submitting.</p>
            </div>
        );
    }

    handleSubmit = ({formData}, e) => {
        const {onSubmit} = this.props;

        formData = dateFieldOverwrite(formData);
        formData = priceFieldOverwrite(formData);
        formData = formDataOverwrite(formData);

        e.preventDefault();
        if (onSubmit) onSubmit(formData);
    };

    render() {
        const {hide, schema, uiSchema} = this.props;

        return (
            <div>
                {hide === true ? (
                    <br></br>
                ) : (
                    <Form
                        ErrorList={this.errorTemplate}
                        noHtml5Validate={true}
                        onSubmit={this.handleSubmit.bind(this)}
                        showErrorList={true}
                        schema={schema}
                        uiSchema={uiSchema}
                    />
                )}
            </div>
        );
    }
}

import React from "react";
import { observer } from "mobx-react";

import { Segment, Container } from "semantic-ui-react";

export const NotFound = observer(() => (
  <React.Fragment>
    <Segment vertical style={{ padding: "40px 0px" }}>
      <Container>
        <h2>Page not found</h2>
        <p>
          We're sorry, the page or record you requested could not be found.
          <br />
          Please go back to the <a href="/">homepage</a> or contact us at{" "}
          <a href="mailto:support@roiltd.co.uk">support@roiltd.co.uk</a>
        </p>
      </Container>
    </Segment>
  </React.Fragment>
));

export default NotFound;

import { get, post, put, upload } from "../helpers/apiHelper";

const EXCHANGE_ENDPOINT = process.env.REACT_APP_BASE_PATH_S3 + "/exchange";

export async function typeFilter(accountId, id) {
  return get(`${EXCHANGE_ENDPOINT}/type/${accountId}/filter?id=${id}`);
}

export async function exchangeInsert(accountId, id, data) {
  const payload = {
    accountId: accountId,
    clientItemId: id,
    payload: data
  };
  return put(`${EXCHANGE_ENDPOINT}/data/`, payload);
}

export async function clientItems(accountId) {
  return get(`${EXCHANGE_ENDPOINT}/items/${accountId}`);
}

export async function exchange(accountId, filters) {
  return post(`${EXCHANGE_ENDPOINT}/data/${accountId}`, filters);
}

export async function exchangeItem(accountId, id) {
  return get(`${EXCHANGE_ENDPOINT}/data/${accountId}/item/${id}`);
}

export async function exchangeUpload(id, formFile) {
  return upload(`${EXCHANGE_ENDPOINT}/${id}/upload`, formFile);
}

import React from "react";
import { Redirect } from "react-router-dom";
import { observer } from "mobx-react";

import { typeFilter } from "../../../controllers/ExchangeController";
import { relayCreateTicket } from "../../../controllers/ResolveController";

import JsonSchemaForm from "../forms/JsonSchemaForm";
import { Container, Header, Image, Loader, Message } from "semantic-ui-react";

export default observer(
  class TicketItem extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        description: "",
        id: 0,
        jsonSchema: null,
        loading: true,
        errors: [],
        name: "",
        params: this.props.match.params,
        success: false
      };
    }

    componentDidMount() {
      if (this.state.loading) this.load();
    }

    load = () => {
      const { params } = this.state;

      typeFilter(params.account, params.id)
        .then(r => {
          const d = r.data[0];

          this.setState({
            description: d.description,
            jsonSchema: d.relayToFreshdesk ? JSON.parse(d.jsonSchema) : "",
            loading: false,
            name: d.name,
            url: r.url
          });
        })
        .catch(() => this.setState({ jsonSchema: "" }));
    };

    handleSubmit = formData => {
      const { params } = this.state;
      this.setState({ errors: [], loading: true, success: false });

      relayCreateTicket(params.account, params.id, formData).then(r =>
        this.setState({
          errors: r.success
            ? []
            : r.errors.map(e => {
                return `${e.code}. ${e.field}, ${e.message}`;
              }),
          id: r.id,
          loading: false,
          success: r.success
        })
      );
    };

    render() {
      const {
        description,
        errors,
        id,
        jsonSchema,
        loading,
        name,
        params,
        success
      } = this.state;

      if (typeof jsonSchema != "object") {
        return <Redirect to="/404" />;
      }

      return (
        <div>
          {loading === true ? (
            <Loader active />
          ) : (
            <Container className="eform-container" style={{ width: "768px" }}>
              <Header as="div">
                <Image
                  src={`/custom-content/${params.account}_logo.png`}
                  size="massive"
                  verticalAlign="top"
                />
                <Header.Content as="h1">
                  {name}
                  <Header.Subheader>{description}</Header.Subheader>
                </Header.Content>
              </Header>
              <Message
                className={success === true ? "" : "hidden"}
                success
                header="Thank you"
                content={`Ticket #${id} has been successfully submitted.`}
              />
              <Message
                className={errors.length === 0 ? "hidden" : ""}
                error
                header="Freshdesk Errors"
                list={errors}
              />
              <JsonSchemaForm
                hide={success}
                onSubmit={this.handleSubmit.bind(this)}
                schema={jsonSchema.schema}
                uiSchema={jsonSchema.uiSchema}
              />
            </Container>
          )}
        </div>
      );
    }
  }
);

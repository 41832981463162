import React from "react";
import { Redirect } from "react-router-dom";
import { observer } from "mobx-react";

// services
import { isAuthenticated, register } from "../../../services/self";
import { confirmMarketplaceSubscription } from "../../../services/api-catalog";

// ui
import { Button, Container, Form, Image, Message } from "semantic-ui-react";

export default observer(
  class RegisterPage extends React.Component {
    state = {
      isSubmitting: false,
      signedIn: isAuthenticated(),
      errorMessage: "",
      isOpen: false
    };

    cancel = () => this.props.history.push("/");

    handleChange = (event, { name, value }) => this.setState({ [name]: value });
    handleRegister = event => {
      event.preventDefault();
      const { accountId, email, password, confirmPassword } = this.state;

      this.setState({ isSubmitting: true });

      if (password !== confirmPassword) {
        this.setState({
          errorMessage: "Passwords do not match",
          isSubmitting: false
        });
        return;
      }

      register(accountId, email, password)
        .then(() => this.ok())
        .catch(e =>
          e.code === "UnknownError"
            ? this.ok()
            : this.setState({ errorMessage: e.message, isSubmitting: false })
        );
    };

    ok = () => {
      this.setState({
        signedIn: true,
        isSubmitting: false,
        errorMessage: ""
      });

      const { usagePlanId, token } = this.props;

      if (usagePlanId && token) {
        return confirmMarketplaceSubscription(usagePlanId, token);
      }
    };

    render = () => {
      return this.state.signedIn ? (
        <Redirect to="/apis" />
      ) : (
        <Container
          style={{ margin: "280px auto", marginTop: "6%", width: "420px" }}
        >
          <div className="box">
            <div className="box-header">
              <Image size="mini" src="/custom-content/nav-logo.png" />
            </div>
            <div className="box-body">
              <h2>drop register</h2>
              <p>
                Please complete the details below to register.
                <br /> If you have any issues please contact us on +44 800 031
                9186.
              </p>
              <Form
                onSubmit={this.handleRegister}
                error={!!this.state.errorMessage}
                loading={this.state.isSubmitting}
              >
                <Form.Input
                  required
                  label="Account ID or alias"
                  name="accountId"
                  onChange={this.handleChange}
                />
                <Form.Input
                  required
                  label="Email"
                  name="email"
                  onChange={this.handleChange}
                />
                <Form.Input
                  required
                  type="password"
                  label="Password"
                  name="password"
                  autoComplete="false"
                  onChange={this.handleChange}
                />

                <Form.Input
                  required
                  type="password"
                  label="Confirm Password"
                  name="confirmPassword"
                  autoComplete="false"
                  onChange={this.handleChange}
                />

                <Message error content={this.state.errorMessage} />
                <div style={{ marginTop: "24px", textAlign: "right" }}>
                  <Button type="button" onClick={this.cancel}>
                    Cancel
                  </Button>
                  <Button primary type="submit">
                    Register
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </Container>
      );
    };
  }
);

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

// content-fragments (import here to start this ASAP)
import "services/get-fragments";

// semantic-ui
import "semantic-ui-css/semantic.css";

// pages
//import Home from "pages/Home";
import Home from "containers/product/pages/ClickToCallPage";
import GettingStarted from "pages/GettingStarted";
import Apis from "pages/Apis";
import NotFound from "pages/NotFound";

import Dashboard from "containers/account/pages/DashboardPage";
import LoginPage from "containers/authentication/pages/LoginPage";
import RegisterPage from "containers/authentication/pages/RegisterPage";

import AgreementItem from "containers/product/pages/AgreementItem";
import AgreementList from "containers/product/pages/AgreementList";
import ClickToCallPage from "containers/product/pages/ClickToCallPage";
import ExchangeItem from "containers/product/pages/ExchangeItem";
import ExchangeList from "containers/product/pages/ExchangeList";
import FormItem from "containers/product/pages/FormItem";
import FormList from "containers/product/pages/FormList";
import TicketItem from "containers/product/pages/TicketItem";
import TicketList from "containers/product/pages/TicketList";

// components
import AlertPopup from "components/AlertPopup";
import NavBar from "components/NavBar";

import { init, isAuthenticated, logout } from "services/self";
import "./css/app.css";
import LogList from "containers/product/pages/LogList";
import LogItem from "containers/product/pages/LogItem";

export const AuthorisedRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated() ? <Component {...props} /> : <Redirect to="/login" />
    }
  />
);

const DefaultContainer = () => (
  <React.Fragment>
    <NavBar />
    <AlertPopup />
    <Switch>
      <Route exact path="/" component={Home} />
      <Route path="/getting-started" component={GettingStarted} />
      <Route path="/click-2-call" component={ClickToCallPage} />
      <Route path="/register" component={RegisterPage} />
      <Route path="/404" component={NotFound} />
      <AuthorisedRoute exact path="/apis" component={Apis} />
      <AuthorisedRoute path="/apis/:apiId" component={Apis} />
      <AuthorisedRoute path="/dashboard" component={Dashboard} />
      <AuthorisedRoute path="/agreement/:key/:id" component={AgreementItem} />
      <AuthorisedRoute path="/agreement" component={AgreementList} />
      <AuthorisedRoute path="/form/:account/:id" component={FormItem} />
      <AuthorisedRoute path="/form" component={FormList} />
      <AuthorisedRoute path="/exchange/:number" component={ExchangeItem} />
      <AuthorisedRoute path="/exchange" component={ExchangeList} />
      <AuthorisedRoute path="/log/:id" component={LogItem} />
      <AuthorisedRoute path="/log" component={LogList} />
      <Route
        path="/logout"
        render={() => {
          logout();
          return <Redirect to="/" />;
        }}
      />
      {/* <Route component={() => <h2>Page not found</h2>} /> */}
    </Switch>
  </React.Fragment>
);

const NoNavContainer = () => (
  <React.Fragment>
    <Route path="/login" component={LoginPage} />
    <Route exact path="/eform/:account" component={TicketList} />
    <Route exact path="/eform/:account/:id" component={TicketItem} />
  </React.Fragment>
);

class App extends React.Component {
  constructor() {
    super();
    init();
    // We are using an S3 redirect rule to prefix the url path with #!
    // This then converts it back to a URL path for React routing
    if (window.location.hash && window.location.hash[1] === "!") {
      const hashRoute = window.location.hash.substring(2);
      window.history.pushState({}, "home page", hashRoute);
    }
  }

  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/(login)" component={NoNavContainer} />
          <Route exact path="/eform/:account" component={NoNavContainer} />
          <Route exact path="/eform/:account/:id" component={NoNavContainer} />
          <Route component={DefaultContainer} />
        </Switch>
      </BrowserRouter>
    );
  }
}

ReactDOM.render(<App />, document.getElementById("root"));

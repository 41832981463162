import _ from "lodash";

export const dateFieldOverwrite = (formData) => {
    const vehicleDetails = _.get(formData, 'vehicle_details');

    if (vehicleDetails && vehicleDetails.hasOwnProperty('df_handover_date') && !isNaN(Date.parse(formData.vehicle_details.df_handover_date))) {
        const date = new Date(formData.vehicle_details.df_handover_date);
        formData.vehicle_details.df_handover_date = date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear();
    }

    return formData;
};


export const priceFieldOverwrite = (formData) => {
    const vehicleDetails = _.get(formData, 'vehicle_details');

    switch (formData.subject) {
        case "E-Form VWCV Agency Price Protection":
        case "E-Form SEAT Agency Price Protection":
        case "E-Form SKODA Agency Price Protection":
        case "E-Form VWPC Agency Price Protection":
        case "E-Form AUDI Agency Price Protection":

            if (vehicleDetails && vehicleDetails.hasOwnProperty('df_on_road_price')) {
                formData.vehicle_details.df_on_road_price = '£ ' + formData.vehicle_details.df_on_road_price;
            }
            break;

        case "E-Form SEAT Agency Price Query Request":
        case "E-Form VWCV Agency Price Query Request":
        case "E-Form AUDI Agency Price Query Request":
        case "E-Form VWPC Agency Price Query Request":
        case "E-Form SKODA Agency Price Query Request":

            if (vehicleDetails && vehicleDetails.hasOwnProperty('df_otr_price')) {
                formData.vehicle_details.df_otr_price = '£ ' + formData.vehicle_details.df_otr_price;
            }
            if (vehicleDetails && vehicleDetails.hasOwnProperty('df_otr_sli')) {
                formData.vehicle_details.df_otr_sli = '£ ' + formData.vehicle_details.df_otr_sli;
            }
            if (vehicleDetails && vehicleDetails.hasOwnProperty('df_po_otr') && (vehicleDetails.df_po_otr !== null && vehicleDetails.df_po_otr !== '')) {
                formData.vehicle_details.df_po_otr = '£ ' + formData.vehicle_details.df_po_otr;
            }

            break;
    }

    return formData;
};

export const formDataOverwrite = (formData) => {
    const vehicleDetails = _.get(formData, 'vehicle_details');
    const contactDetails = _.get(formData, 'contact_details');
    const queryDetails = _.get(formData, 'query_details');

    console.log(formData)

    switch (formData.subject) {
        case "E-Form VWCV Agency Price Protection":
        case "E-Form SEAT Agency Price Protection":
        case "E-Form SKODA Agency Price Protection":
        case "E-Form VWPC Agency Price Protection":
        case "E-Form AUDI Agency Price Protection":

        case "E-Form SEAT Agency Price Query Request":
        case "E-Form VWCV Agency Price Query Request":
        case "E-Form AUDI Agency Price Query Request":
        case "E-Form VWPC Agency Price Query Request":
        case "E-Form SKODA Agency Price Query Request":

            if (vehicleDetails && vehicleDetails.hasOwnProperty('cf_handover_committed_date')) {
                formData.vehicle_details.df_handover_committed_date = formData.vehicle_details.cf_handover_committed_date;
            }
            if (vehicleDetails && vehicleDetails.hasOwnProperty('cf_physical_status')) {
                formData.vehicle_details.df_physical_status = formData.vehicle_details.cf_physical_status;
            }
            if (vehicleDetails && vehicleDetails.hasOwnProperty('cf_clarification_of_the_issue')) {
                formData.vehicle_details["df_clarification_of_the_issue"]  = formData.vehicle_details.cf_clarification_of_the_issue;
            }

        case "E-Form VWPC System/Product Error Message":
        case "E-Form SKODA System/Product Error Message":
        case "E-Form VWCV System/Product Error Message":
        case "E-Form SEAT System/Product Error Message":
        case "E-Form AUDI System/Product Error Message":

        case "E-Form SKODA Agency Aged Stock / Operations Pack":
        case "E-Form VWPC Agency Aged Stock / Operations Pack":
        case "E-Form VWCV Agency Aged Stock / Operations Pack":
        case "E-Form AUDI Agency Aged Stock / Operations Pack":
        case "E-Form SEAT Agency Aged Stock / Operations Pack":

            if (vehicleDetails && vehicleDetails.hasOwnProperty('cf_sli_order_number')) {
                formData.vehicle_details.df_sli_order_number = formData.vehicle_details.cf_sli_order_number;
            }
            if (vehicleDetails && vehicleDetails.hasOwnProperty('cf_sli_quote')) {
                formData.vehicle_details.df_sli_quote = formData.vehicle_details.cf_sli_quote;
            }
            if (contactDetails && contactDetails.hasOwnProperty('cf_retailer_number')) {
                formData.contact_details.df_retailer_number = formData.contact_details.cf_retailer_number;
            }

            break;

        case "E-Form VWCV GFS Support/Action Request":
        case "E-Form VWPC GFS Support/Action Request":
        case "E-Form SEAT GFS Support/Action Request":
        case "E-Form AUDI GFS Support/Action Request":
        case "E-Form SKODA GFS Support/Action Request":

            if (queryDetails && queryDetails.hasOwnProperty('cf_sli_order_number')) {
                formData.query_details.df_sli_order_number = formData.query_details.cf_sli_order_number;
            }
            if (contactDetails && contactDetails.hasOwnProperty('cf_retailer_number')) {
                formData.contact_details.df_retailer_number = formData.contact_details.cf_retailer_number;
            }

            break;

        case "E-Form SEAT GFS Call Back Request":
        case "E-Form VWPC GFS Call Back Request":
        case "E-Form AUDI GFS Call Back Request":
        case "E-Form SKODA GFS Call Back Request":
        case "E-Form VWCV GFS Call Back Request":

        case "E-Form UAT RCH Direct Query":
        case "E-Form SEAT RCH Direct Query":
        case "E-Form VWPC RCH Direct Query":
        case "E-Form AUDI RCH Direct Query":
        case "E-Form SKODA RCH Direct Query":
        case "E-Form VWCV RCH Direct Query":

            if (contactDetails && contactDetails.hasOwnProperty('cf_retailer_number')) {
                formData.contact_details.df_retailer_number = formData.contact_details.cf_retailer_number;
            }

            if (queryDetails && queryDetails.hasOwnProperty('cf_handover_committed_date')) {
                formData.query_details.df_handover_committed_date = formData.query_details.cf_handover_committed_date;
            }

            break;
    }

    return formData
};


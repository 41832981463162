import axios from "axios";
import { getUser } from "../services/self";

export async function get(url) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${url}`)
      .then(response => {
        resolve({
          data: response.data,
          url: response.config.url
        });
      })
      .catch(error => {
        reject(error);
      });
  });
}

export async function post(url, payload) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${url}`, payload)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export async function put(url, payload) {
  return new Promise((resolve, reject) => {
    axios
      .put(`${url}`, payload)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export async function upload(url, formFile) {
  const user = getUser();

  return new Promise((resolve, reject) => {
    axios
      .put(`${url}`, formFile, {
        headers: {
          "Content-Type": "multipart/form-data",
          userId: `${user.username}`
        }
      })
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

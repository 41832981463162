import React from "react";
import { Button, Form, Message, Modal, Select } from "semantic-ui-react";
import { Redirect } from "react-router-dom";
// import { bytesToSize } from "./../../../helpers/formatHelper.js";

import {
  clientItems,
  exchangeUpload
} from "../../../controllers/ExchangeController";

const maxLength = 6291456;

export default class UploadModal extends React.Component {
  state = {
    accountId: localStorage.getItem("accountId"),
    errorMessage: "",
    isOpen: false,
    isUploading: false,
    items: []
  };

  componentDidMount() {
    clientItems(this.state.accountId).then(r =>
      this.setState({ items: r.data })
    );
  }

  open = () =>
    this.setState({ isUploading: false, errorMessage: "", isOpen: true });

  close = () => this.setState({ isOpen: false });

  reset = () => {
    setTimeout(() => {
      this.setState({
        isUploading: false,
        errorMessage: ""
      });
      window.location.reload();
    }, 1000);
  };

  handleChange2 = ev => {
    this.setState({ success: false, url: "" });
  };

  handleChange = (event, { name, value }) => this.setState({ [name]: value });

  handleUpload = event => {
    event.preventDefault();
    this.setState({ isSubmitting: true });

    let fi = this.uploadInput.files[0];

    if (fi.size > maxLength) {
      this.setState({
        errorMessage: "Sorry, the maximum size for uploaded files is 6MB.",
        isSubmitting: false
      });
      // console.log(bytesToSize(fi.size));
      return;
    }

    let fd = new FormData();
    fd.append("file", fi);

    exchangeUpload(this.state.clientItemId, fd)
      .then(() => this.reset())
      .catch(e =>
        this.setState({ errorMessage: e.message, isUploading: false })
      );
  };

  render() {
    const { isOpen } = this.state;

    return this.state.isUploading ? (
      <Redirect to="/exchange" />
    ) : (
      <Modal
        size="small"
        open={isOpen}
        onOpen={this.open}
        onClose={this.close}
        trigger={this.props.trigger}
      >
        <Modal.Header>Upload</Modal.Header>
        <Modal.Content>
          <Form
            onSubmit={this.handleUpload}
            error={!!this.state.errorMessage}
            loading={this.state.isSubmitting}
          >
            <Form.Field
              control={Select}
              label="Template"
              name="clientItemId"
              onChange={this.handleChange}
              options={this.state.items}
              placeholder="Please select"
            />
            <h5>Limitations:</h5>
            <ul id="limitations">
              <li>
                <span>We accept the following file types </span>
                <strong>.csv</strong> <strong>.psv</strong>{" "}
                <strong>.xml</strong>
              </li>
              <li>The maximum file size is 6MB.</li>
            </ul>
            <input
              required
              name="upload"
              onChange={this.handleChange2}
              ref={ref => {
                this.uploadInput = ref;
              }}
              accept=".csv,.psv,.xml"
              type="file"
            />
            <br />
            <br />
            <Message error content={this.state.errorMessage} />
            <Modal.Actions style={{ textAlign: "right" }}>
              <Button.Group>
                <Button type="button" onClick={this.close}>
                  Cancel
                </Button>
                <Button positive type="submit">
                  Upload
                </Button>
              </Button.Group>
            </Modal.Actions>
          </Form>
        </Modal.Content>
      </Modal>
    );
  }
}

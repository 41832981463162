import React from "react";
import { Redirect } from "react-router-dom";
import { observer } from "mobx-react";

import {
  typeFilter,
  exchangeInsert
} from "../../../controllers/ExchangeController";

import JsonSchemaForm from "../forms/JsonSchemaForm";
import { Container, Header, Icon, Loader, Tab } from "semantic-ui-react";

export default observer(
  class FormItem extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        relayToFreshdesk: false,
        data: null,
        description: "A sample form.",
        jsonSchema: null,
        loading: true,
        name: "FORM",
        params: this.props.match.params,
        result: {
          id: "00000000-0000-0000-0000-000000000000"
        },
        url: null
      };
    }

    componentDidMount() {
      if (this.state.loading) this.load();
    }

    load = () => {
      const { params } = this.state;

      typeFilter(params.account, params.id)
        .then(r => {
          const d = r.data[0];

          this.setState({
            relayToFreshdesk: d.relayToFreshdesk,
            jsonSchema: JSON.parse(d.jsonSchema),
            loading: false,
            name: d.name,
            data: {
              accountId: params.account,
              clientItemId: params.id,
              payload: []
            },
            description: d.description,
            url: r.url
          });
        })
        .catch(() => this.setState({ jsonSchema: "" }));
    };

    handleSubmit = formData => {
      //e.preventDefault();
      const { data, params } = this.state;
      data.payload.length = 0;
      data.payload.push(formData);

      exchangeInsert(params.account, params.id, data.payload).then(r =>
        this.setState({ result: r.payload })
      );

      this.setState({ data: data });
    };

    render() {
      const {
        data,
        description,
        jsonSchema,
        loading,
        name,
        params,
        relayToFreshdesk,
        result,
        url
      } = this.state;

      if (typeof jsonSchema != "object") {
        return <Redirect to="/404" />;
      }

      const panes = [
        {
          menuItem: "Render",
          render: () => (
            <Tab.Pane>
              <JsonSchemaForm
                schema={jsonSchema.schema}
                uiSchema={jsonSchema.uiSchema}
                onSubmit={this.handleSubmit.bind(this)}
              />
            </Tab.Pane>
          )
        },
        {
          menuItem: "Schema",
          render: () => (
            <Tab.Pane>
              <pre>{JSON.stringify(jsonSchema, null, 2)}</pre>
            </Tab.Pane>
          )
        }
      ];
      return (
        <div>
          {loading === true ? (
            <Loader active />
          ) : (
            <div>
              <Container style={{ padding: "40px 0px" }}>
                <Header as="h2" className="clear-top">
                  <Icon name="wordpress forms" />
                  <Header.Content>
                    {name}
                    <Header.Subheader>{description}</Header.Subheader>
                  </Header.Content>
                </Header>
                <p>
                  Our goal is simple: make it easy for our clients to talk to
                  their customers and make it easy for users to get in touch
                  with businesses.
                </p>
                <p>
                  Return On Investments's APIs belong to the Representational
                  State Transfer (REST) category. They allow you to perform
                  'RESTful' operations such as reading, modifying, adding or
                  deleting data from your workflow. The APIs also support
                  Cross-Origin Resource Sharing (CORS).
                </p>
                <ul style={{ marginBottom: "32px" }}>
                  <li>
                    <strong>Endpoint </strong>
                    <a href={url} rel="noopener noreferrer" target="_blank">
                      {url}
                    </a>
                  </li>
                  {relayToFreshdesk ? (
                    <li>
                      <strong>EForm </strong>
                      <a
                        href={`/eform/${params.account}/${params.id}`}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        {`/eform/${params.account}/${params.id}`}
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                </ul>
                <h4>Curl</h4>
                <p>
                  <code>
                    {'curl -X PUT "https://api.roicloud.co.uk/s3/dev/exchange/data/" -H "accept: application/json" -H "Content-Type: application/json-patch+json" -d \'' +
                      JSON.stringify(data, null, 2) +
                      "'"}
                  </code>
                </p>
                <h4>Response</h4>
                <p>
                  <code>{JSON.stringify(result, null, 2)}</code>
                </p>
                <br />
                <Tab panes={panes} />
              </Container>
            </div>
          )}
        </div>
      );
    }
  }
);

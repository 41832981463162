import React from "react";
import { observer } from "mobx-react";

import { typeFilter } from "../../../controllers/ExchangeController";

import {
  Card,
  Container,
  Header,
  Icon,
  Loader,
  Segment
} from "semantic-ui-react";

export default observer(
  class FormList extends React.Component {
    state = {
      accountId: localStorage.getItem("accountId"),
      items: [],
      loading: true
    };

    componentDidMount() {
      if (this.state.loading) this.load();
    }

    load = () => {
      const { accountId } = this.state;

      typeFilter(accountId, null)
        .then(r => {
          let d = r.data;

          d = d
            .filter(q => q.jsonSchema != null)
            .map(p => {
              return {
                header: p.name,
                color: p.relayToFreshdesk ? "purple" : "blue",
                description: p.description,
                href: "/form/" + accountId + "/" + p.id
              };
            });

          this.setState({
            items: d,
            loading: false
          });
        })
        .catch(() => this.setState({ items: [] }));
    };

    render() {
      const { loading, items } = this.state;
      console.log(items);

      return (
        <div>
          {loading === true ? (
            <Loader active />
          ) : (
            <div>
              <Segment
                vertical
                textAlign="center"
                style={{
                  color: "whitesmoke",
                  backgroundColor: "#0067b8d6",
                  padding: "40px 0px",
                  margin: "0px !important"
                }}
              >
                <Icon name="wordpress forms" size="massive" />
                <Header as="h1" style={{ color: "whitesmoke" }}>
                  Data Forms
                </Header>
                <p>Form rendering and data submissions</p>
              </Segment>
              <Container style={{ padding: "40px 0px" }}>
                <Header as="h2" className="clear-top">
                  <Icon name="wordpress forms" />
                  <Header.Content>
                    TEMPLATES
                    <Header.Subheader>
                      Choose a schema template from the list.
                    </Header.Subheader>
                  </Header.Content>
                </Header>
                <p>
                  The key here is that data dumps can now be carried out, with
                  commonality and uniformity, by someone with no technical
                  knowledge of our systems at all.
                  <br />
                  To start this process – we need a Form or specifically a Form
                  definition. I’ve created a few simples using JsonSchema to
                  capture all of the information required for a given context.
                </p>
                <h3>Samples ({items.length})</h3>
                <Card.Group itemsPerRow={3} items={items} />
                <br />
                <hr />
                <h3>Developer Options</h3>
                <h4>Json.NET Schema</h4>
                <a
                  href="https://www.newtonsoft.com/jsonschema"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  https://www.newtonsoft.com/jsonschema
                </a>
                <p>
                  Designed with performance in mind, Json.NET Schema's unique
                  streaming validation enables fast, efficient validation over
                  any JSON. Json.NET Schema is over twice as fast as the .NET
                  Framework's XmlSchema when validating equivalent data.
                </p>
                <h4>react-jsonschema-form</h4>
                <a
                  href="https://github.com/mozilla-services/react-jsonschema-form"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  https://github.com/mozilla-services/react-jsonschema-form
                </a>
                <p>
                  react-jsonschema-form is meant to automatically generate a
                  React form based on a JSON Schema. It is a major component in
                  the kinto-admin project. If you want to generate a form for
                  any data, sight unseen, simply given a JSON schema,
                  react-jsonschema-form may be for you. If you have a priori
                  knowledge of your data and want a toolkit for generating forms
                  for it, you might look elsewhere.
                </p>
                <h4>angular-json-schema-form</h4>
                <a
                  href="https://www.npmjs.com/package/angular6-json-schema-form"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  https://www.npmjs.com/package/angular6-json-schema-form
                </a>
                <p>
                  This example playground features over 70 different JSON
                  Schemas for you to try (including all examples used by each of
                  the three libraries listed above), and the ability to quickly
                  view any example formatted with Material Design, Bootstrap 3,
                  Bootstrap 4, or without any formatting.
                </p>
                <hr />
                <h3>Construction and Guides</h3>
                <h4>JSON Schema</h4>
                <a
                  href="https://github.com/json-schema-org"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  https://github.com/json-schema-org
                </a>
                <p>
                  JSON Schema is a vocabulary that allows you to validate,
                  annotate, and manipulate JSON documents.
                </p>
                <h4>JSON Schema Validator</h4>
                <a
                  href="https://www.jsonschemavalidator.net/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  https://www.jsonschemavalidator.net/
                </a>
                <p>
                  An online, interactive JSON Schema validator. Supports JSON
                  Schema Draft 3, Draft 4, Draft 6 and Draft 7.
                </p>
              </Container>
            </div>
          )}
        </div>
      );
    }
  }
);

import React from "react";

export default class ExchangeFilter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      className: props.className ? props.className : "",
      hide: props.hide ? props.hide : false,
      title: props.title ? props.title : null
    };
  }

  handleClick = (fieldName, key, text) => {
    const { onClick } = this.props;
    if (onClick) onClick(fieldName, key, text);
  };

  itemClass = index => {
    let common = "tile-item";
    switch (index) {
      case "-2":
        return `${common} failure`;
      case "-1":
        return `${common} invalid`;
      case "1":
        return `${common} waiting`;
      case "2":
        return `${common} success`;
      default:
        return common;
    }
  };

  renderItem = (item, filter) => {
    return (
      <li
        key={item.key}
        className={this.itemClass(item.key)}
        onClick={this.handleClick.bind(
          this,
          item.fieldName,
          item.key,
          item.text
        )}
      >
        <span className="tile-count">{item.value}</span>
        <span className="tile-caption">{item.text}</span>
      </li>
    );
  };

  render() {
    const { className, hide, title } = this.state;
    const { items } = this.props;

    return hide ? null : (
      <div className="contain">
        {!title ? null : <h4>{title}</h4>}
        <ul className={className}>
          {items.map(item => this.renderItem(item))}
        </ul>
      </div>
    );
  }
}

import React from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";

import { list } from "../../../controllers/AgreementController";
import ListFilter from "../components/ListFilter";
import ListSearch from "../components/ListSearch";

import {
  Button,
  Grid,
  Header,
  Icon,
  Loader,
  Menu,
  Table
} from "semantic-ui-react";

export default observer(
  class AgreementList extends React.Component {
    state = {
      accountId: localStorage.getItem("accountId"),
      activePage: 1,
      key: null,
      companies: [],
      endpoint: null,
      loading: true,
      pageCount: 0,
      payload: null,
      recordCount: 0,
      searchKeyword: null,
      selectedCompany: null
    };

    componentDidMount() {
      if (this.state.loading) this.load();
    }

    handleChange = (e, { name, value }) => this.setState({ [name]: value });

    handlePaginationChange = (e, { name }) => {
      const { activePage, searchKeyword, selectedCompany } = this.state;
      let n = activePage + (name === "next" ? 1 : -1);
      this.setState({ activePage: n });
      this.load(selectedCompany, n, searchKeyword);
    };

    load = (descriptor, pageNumber, search) => {
      const { accountId } = this.state;

      list(accountId, descriptor, pageNumber, search).then(r =>
        this.setState({
          endpoint: r.url,
          loading: false,
          payload: r.data,
          pageCount: r.data.information[0].rnPages,
          recordCount: r.data.information[0].rnCount
        })
      );
    };

    filter = (fieldName, key, text) => {
      this.setState({ searchKeyword: null, selectedCompany: text });
      this.load(text);
    };

    reload = e => {
      e.preventDefault();
      this.setState({
        loading: true,
        searchKeyword: null,
        selectedCompany: null
      });
      setTimeout(() => {
        this.load(null, 1, null);
      }, 500);
    };

    search = value => {
      this.setState({ searchKeyword: value, selectedCompany: null });
      this.load(null, null, value);
    };

    companyItem = item => {
      return (
        <li key={item.key}>
          <span className="tile-caption">{item.text}</span>
        </li>
      );
    };

    tableRow(data) {
      const { accountId } = this.state;
      return data.map(function(item, k) {
        return (
          <Table.Row key={k}>
            <Table.Cell width="2">
              <Link to={`/agreement/${accountId}/${item.id}`}>{item.key}</Link>
            </Table.Cell>
            <Table.Cell width="8">{item.descriptor}</Table.Cell>
            <Table.Cell width="3">
              {new Date(item.updateDate).toLocaleString("en-GB")}
            </Table.Cell>
            <Table.Cell width="3">
              {new Date(item.publishDate).toLocaleString("en-GB")}
            </Table.Cell>
          </Table.Row>
        );
      });
    }

    render() {
      const {
        activePage,
        pageCount,
        endpoint,
        loading,
        payload,
        recordCount,
        searchKeyword,
        selectedCompany
      } = this.state;

      return (
        <div>
          {loading === true ? (
            <Loader active />
          ) : (
            <Grid columns={2} style={{ padding: "40px 24px" }}>
              <Grid.Row>
                <Grid.Column computer={4} widescreen={3}>
                  <div className="contain">
                    <ListFilter
                      className="item-list"
                      hide={false}
                      items={payload.companies}
                      onClick={this.filter.bind(this)}
                      title="Companies"
                    />
                  </div>
                </Grid.Column>
                <Grid.Column computer={12} widescreen={13}>
                  <div
                    style={{
                      float: "right"
                    }}
                  >
                    <ListSearch onKeyDown={this.search.bind(this)} />
                    <Button
                      basic
                      icon="refresh"
                      onClick={this.reload}
                      title="refresh"
                    />
                  </div>
                  <Header as="h2" className="clear-top">
                    <Icon name="certificate" />
                    <Header.Content>
                      MOCK AGREEMENT LIST
                      <Header.Subheader>
                        {" "}
                        <a
                          href={endpoint}
                          rel="noopener noreferrer"
                          target="_blank"
                          title="source"
                        >
                          {endpoint}
                        </a>
                      </Header.Subheader>
                    </Header.Content>
                  </Header>
                  <h4>
                    {selectedCompany != null
                      ? "Filtered "
                      : searchKeyword != null
                      ? `Search results for '${searchKeyword}' `
                      : "All "}
                    Records ({recordCount})
                  </h4>
                  <Table celled>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Reference</Table.HeaderCell>
                        <Table.HeaderCell>Description</Table.HeaderCell>
                        <Table.HeaderCell>Last Updated</Table.HeaderCell>
                        <Table.HeaderCell>Published</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>{this.tableRow(payload.agreements)}</Table.Body>
                    <Table.Footer>
                      <Table.Row>
                        <Table.HeaderCell colSpan="4">
                          <Menu floated="right" pagination>
                            <Menu.Item
                              disabled={activePage === 1}
                              name="prev"
                              onClick={this.handlePaginationChange}
                            >
                              {"< Prev"}
                            </Menu.Item>
                            <Menu.Item as="a">
                              Page {activePage} of {pageCount}
                            </Menu.Item>
                            <Menu.Item
                              disabled={activePage === pageCount}
                              name="next"
                              onClick={this.handlePaginationChange}
                            >
                              {"Next >"}
                            </Menu.Item>
                          </Menu>
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Footer>
                  </Table>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          )}
        </div>
      );
    }
  }
);

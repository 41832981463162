import { get } from "../helpers/apiHelper";

const AGREEMENT_ENDPOINT = process.env.REACT_APP_BASE_PATH_AGREEMENT;

export async function list(accountId, filter, pageNumber, search) {
  let path = `${AGREEMENT_ENDPOINT}/${accountId}?pn=${
    pageNumber != null ? pageNumber : 1
  }`;
  if (filter != null) path += `&fd=${filter}`;
  if (search != null) path += `&sn=${search}`;

  return get(path);
}

export async function item(accountId, id) {
  return get(`${AGREEMENT_ENDPOINT}/${accountId}/${id}`);
}

export async function itemLog(accountId, id) {
  return get(`${AGREEMENT_ENDPOINT}/${accountId}/${id}/log`);
}

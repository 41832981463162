import React from "react";
import { observer } from "mobx-react";
import { Button, Container, Form, Grid, Icon, Label, Message } from "semantic-ui-react";
import axios from "axios";

// fragments
import { fragments } from "../../../services/get-fragments";

export default observer(
  class ClickToCallPage extends React.Component {
    state = {
      scheduleDate: new Date(),
      isSubmitting: false,
      errorMessage: "awaiting submission"
    };

    handleChange = (event, { name, value }) => this.setState({ [name]: value });

    handleSubmit = event => {
      event.preventDefault();
      this.setState({ isSubmitting: true });

      const body = {
        accountId: this.state.accountId,
        name: this.state.name,
        phoneNumber: this.state.phoneNumber,
        scheduleDate: this.state.scheduleDate
      };

      axios({
        method: "put",
        url: "https://api.roicloud.co.uk/coms/dev/callback",
        data: body,
        config: { headers: { "Content-Type": "application/json" } }
      })
        .then(r =>
          this.setState({
            errorMessage: JSON.stringify(r.data),
            isSubmitting: false
          })
        )
        .catch(e =>
          this.setState({ errorMessage: e.message, isSubmitting: false })
        );
    };

    render() {
      return (
        <Container style={{ padding: "40px 0px" }}>
          <fragments.TwilioApi.jsx />
          <Grid columns={2} divided>
            <Grid.Row>
              <Grid.Column>
                <br />
                <Form onSubmit={this.handleSubmit}>
                  <Form.Input
                    label="Account Id"
                    placeholder="596530310008"
                    name="accountId"
                    required
                    onChange={this.handleChange}
                  />
                  <Form.Input
                    label="Name"
                    name="name"
                    required
                    onChange={this.handleChange}
                  />
                  <Form.Input
                    label="Phone Number"
                    name="phoneNumber"
                    required
                    onChange={this.handleChange}
                  />
                  <Form.Input
                    label="Schedule Date"
                    placeholder="(local) 2019-04-05T09:30:00.000Z"
                    name="scheduleDate"
                    value={this.state.scheduleDate}
                    readOnly
                  />
                  <br />
                  <br />
                  <Button
                    disabled={this.state.isSubmitting}
                    primary
                    style={{ float: "right" }}
                    type="submit"
                  >
                    Submit Request
                  </Button>
                  <Button type="reset">Reset</Button>
                </Form>
              </Grid.Column>
              <Grid.Column>
                <Label>
                  {" "}
                  <Icon name="bolt" /> API Response
                </Label>
                <Message className="code" content={this.state.errorMessage} />
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <fragments.NeedHelp.jsx />
        </Container>
      );
    }
  }
);

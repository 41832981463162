import React from "react";
import { Link } from "react-router-dom";
import { Icon, Image, Menu } from "semantic-ui-react";

import { isAuthenticated, logout } from "services/self";

// mobx
import { observer } from "mobx-react";

// fragments
import { fragments } from "services/get-fragments";

export const NavBar = observer(() => {
  return (
    <Menu inverted borderless attached style={{ flex: "0 0 auto" }}>
      <Menu.Item as={Link} to="/">
        <Image
          size="mini"
          src="/custom-content/nav-logo.png"
          style={{ paddingRight: "10px" }}
        />
        <strong>{fragments.Home.title}</strong>
      </Menu.Item>
      <Menu.Item as={Link} to="/apis">
        {fragments.APIs.title}
      </Menu.Item>
      {insertAuthMenu(isAuthenticated())}
      {insertAccountMenu(isAuthenticated())}
    </Menu>
  );
});

function insertAuthMenu(authenticated) {
  if (authenticated) {
    return (
      <Menu.Menu>
        <Menu.Item key="agreement" as={Link} to="/agreement">
          Agreements
        </Menu.Item>
        <Menu.Item key="form" as={Link} to="/form">
          Forms
        </Menu.Item>
        <Menu.Item key="exchange" as={Link} to="/exchange">
          Exchange
        </Menu.Item>
          <Menu.Item key="log" as={Link} to="/log">
              Log
          </Menu.Item>
      </Menu.Menu>
    );
  }
}

function insertAccountMenu(authenticated) {
  if (authenticated) {
    return (
      <Menu.Menu position="right">
        <Menu.Item key="dashboard" as={Link} title="my account" to="/dashboard">
          <Icon name="user" />
        </Menu.Item>
        <Menu.Item key="signout" as="a" onClick={logout} title="sign out">
          <Icon name="power off" />
        </Menu.Item>
      </Menu.Menu>
    );
  } else {
    return (
      <Menu.Menu position="right">
        <Menu.Item key="login" as={Link} to="/login">
          Sign in
        </Menu.Item>
        <Menu.Item key="register" as={Link} to="/register">
          Free account
        </Menu.Item>
      </Menu.Menu>
    );
  }
}

export default NavBar;

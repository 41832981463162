import React, { Component } from "react";
import _ from "lodash";

import { Icon } from "semantic-ui-react";

export default class ListSearch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      search: {
        isLoading: false,
        value: ""
      }
    };
  }

  handleChange = e => {
    this.setState({ search: { value: e.target.value } });
  };

  handleSubmit = e => {
    if (e.key === "Enter") {
      setTimeout(() => {
        const { onKeyDown } = this.props;
        if (onKeyDown) onKeyDown(this.state.search.value);
        this.resetComponent();
      }, 300);
    }
  };

  resetComponent = () =>
    this.setState(prev => ({
      ...prev,
      search: { isLoading: false, value: "" }
    }));

  render() {
    const { value } = this.state.search;

    return (
      <div
        style={{
          display: "inline",
          margin: "0 8px",
          overflow: "hidden"
        }}
      >
        <div className="ui icon input">
          <input
            style={{ lineHeight: "19px", padding: "8px" }}
            autoComplete="off"
            name="search"
            onChange={_.debounce(this.handleChange, 500, {
              leading: true
            })}
            onKeyPress={this.handleSubmit}
            placeholder="Keyword search"
            type="text"
            maxLength="20"
            value={value}
          />
          <Icon name="search" />
        </div>
      </div>
    );
  }
}

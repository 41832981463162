import React from "react";
import { observer } from "mobx-react";
import { download, filter } from "../../../controllers/LoggerController";
import { Button, Grid, Header, Icon, Loader, Table } from "semantic-ui-react";

class LogList extends React.Component {
  state = {
    accountId: localStorage.getItem("accountId"),
    loading: true,
    logs: []
  };

  componentDidMount() {
    if (this.state.loading) this.load();
  }

  load = () => {
    const { accountId } = this.state;

    filter(accountId).then(r =>
      this.setState({
        loading: false,
        logs: r.data
      })
    );
  };

  download = e => {
    e.preventDefault();
    const { accountId } = this.state;

    download(accountId).then(r => console.log("download"));
  };

  reload = e => {
    e.preventDefault();
    this.setState({
      loading: true
    });
    setTimeout(() => {
      this.load(null, 1, null);
    }, 500);
  };

  mapToRows(logs) {
    if (logs.length === 0) {
      return (
        <Table.Row>
          <Table.Cell colSpan="3">No Records</Table.Cell>
        </Table.Row>
      );
    }

    return logs.map(x => {
      return (
        <Table.Row key={x.id}>
          <Table.Cell>{x.createDate}</Table.Cell>
          <Table.Cell>{x.message}</Table.Cell>
        </Table.Row>
      );
    });
  }

  render() {
    const { loading, logs } = this.state;
    return (
      <div>
        {loading === true ? (
          <Loader active content="Loading" />
        ) : (
          <Grid columns={1} style={{ padding: "50px" }}>
            <Grid.Row>
              <Grid.Column>
                <div
                  style={{
                    float: "right"
                  }}
                >
                  <Button basic onClick={this.download} content="Download" />
                  <Button
                    basic
                    icon="refresh"
                    onClick={this.reload}
                    title="refresh"
                  />
                </div>
                <Header as="h2" className="clear-top">
                  <Icon name="file alternate outline" />
                  <Header.Content>
                    LOG STORE
                    <Header.Subheader>
                      Listing application messages for selected account.
                    </Header.Subheader>
                  </Header.Content>
                </Header>
                <h4>All Records ({logs.length})</h4>
                <Table fixed selectable>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell width="3">Created</Table.HeaderCell>
                      <Table.HeaderCell>Message</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>{this.mapToRows(logs)}</Table.Body>
                </Table>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}
      </div>
    );
  }
}

export default observer(LogList);

import React from "react";
import { Redirect } from "react-router-dom";
import { observer } from "mobx-react";

import { itemLog } from "../../../controllers/AgreementController";

import { Button, Grid, Header, Icon, Loader } from "semantic-ui-react";

export default observer(
  class AgreementItem extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        endpoint: null,
        loading: true,
        payload: null,
        params: this.props.match.params,
        selectedIndex: 0
      };
    }

    componentDidMount() {
      if (this.state.loading) this.load();
    }

    handleRevisionClick = n => {
      const { payload } = this.state;
      const index = payload.map(e => e.revision).indexOf(n);
      this.setState({ selectedIndex: index });
    };

    load = () => {
      const { params } = this.state;
      itemLog(params.key, params.id)
        .then(r =>
          this.setState({ endpoint: r.url, loading: false, payload: r.data })
        )
        .catch(() => this.setState({ payload: "" }));
    };

    renderItem = item => {
      return (
        <li
          key={item.revision}
          onClick={this.handleRevisionClick.bind(this, item.revision)}
        >
          <span className="tile-count">{item.revision} </span>
          <span className="tile-caption">{item.published}</span>
        </li>
      );
    };

    render() {
      const { endpoint, loading, payload, selectedIndex } = this.state;

      if (typeof payload != "object") {
        return <Redirect to="/404" />;
      }

      return (
        <div>
          {loading === true ? (
            <Loader active />
          ) : (
            <Grid columns={3} divided style={{ padding: "40px 24px" }}>
              <Grid.Row>
                <Grid.Column computer={4} widescreen={3}>
                  <div className="contain" style={{ marginBottom: "64px" }}>
                    <Button
                      className="link"
                      onClick={this.props.history.goBack}
                    >
                      <Icon name="angle left" /> Back to Listing
                    </Button>
                  </div>
                  <div className="contain">
                    <h4>Record</h4>
                    <p>Id: {payload[selectedIndex].id}</p>
                    <p>Published: {payload[selectedIndex].published}</p>
                    <p>Last Updated: {payload[0].updateDate}</p>
                    <h4>Document</h4>
                    <p>Reference: {payload[0].key}</p>
                    <p>Description: {payload[0].descriptor}</p>
                    <p>Revision: {payload[selectedIndex].revision}</p>
                    <h4>Revisions</h4>
                    <ul className="item-list">
                      {payload.map(item => this.renderItem(item))}
                    </ul>
                  </div>
                </Grid.Column>
                <Grid.Column computer={12} widescreen={13}>
                  <Header as="h2" className="clear-top">
                    <Icon name="certificate" />
                    <Header.Content>
                      AGREEMENT DOCUMENT
                      <Header.Subheader>
                        <a
                          href={endpoint}
                          rel="noopener noreferrer"
                          target="_blank"
                          title="source"
                        >
                          {endpoint}
                        </a>
                      </Header.Subheader>
                    </Header.Content>
                  </Header>
                  <h4>
                    ({payload[0].key}) ({payload[selectedIndex].revision})
                  </h4>
                  <div style={{ borderTop: "solid 1px #ddd" }}>
                    <pre>
                      {JSON.stringify(payload[selectedIndex].document, null, 2)}
                    </pre>
                  </div>
                </Grid.Column>
                <Grid.Column computer={12} widescreen={13} />
              </Grid.Row>
            </Grid>
          )}
        </div>
      );
    }
  }
);

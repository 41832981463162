import React from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";

import { toFileSize } from "../../../services/misc";
import { isAuthenticated } from "../../../services/self";
import { exchange } from "../../../controllers/ExchangeController";
import ExchangeFilter from "../components/ListFilter";

import {
  Button,
  Grid,
  Header,
  Icon,
  Loader,
  Menu,
  Table
} from "semantic-ui-react";
import UploadModal from "../forms/UploadModal";

export default observer(
  class ExchangeList extends React.Component {
    state = {
      accountId: localStorage.getItem("accountId"),
      filters: [],
      payload: null,
      loading: true
    };

    componentDidMount() {
      if (this.state.loading) this.load();
    }

    load = () => {
      const { accountId, filters } = this.state;

      exchange(accountId, filters).then(d =>
        this.setState({ payload: d.payload, loading: !d.success })
      );
    };

    removeFilter = index => {
      let a = this.state.filters;
      a.splice(index, 1);
      this.setState({ filters: a });
      this.load();
    };

    getFilter = name => {
      const { payload } = this.state;
      let list = payload.options.filter(item => item.fieldName === name);
      return list;
    };

    filter = (fieldName, key, text) => {
      let a = this.state.filters;
      let c = { FieldName: fieldName, Key: key, Selected: 1, Text: text };

      if (a.includes(key) === false) a.push(c);

      this.setState({ filters: a });
      this.load();
    };

    reload = e => {
      e.preventDefault();
      this.setState({ filters: [], loading: true });
      setTimeout(() => {
        this.load();
      }, 500);
    };

    tableRow(data) {
      return data.map(function(item, key) {
        return (
          <Table.Row
            className={item.exchangeState.id < 0 ? "error" : "positive"}
            key={key}
            width="1"
          >
            <Table.Cell>
              <Icon
                name={item.exchangeState.id < 0 ? "attention" : "checkmark"}
              />
            </Table.Cell>
            <Table.Cell width="5">
              <Link to={`/exchange/${item.id}`}>
                {item.key == null ? item.descriptor : item.key}
              </Link>
            </Table.Cell>
            <Table.Cell width="3">{item.clientItem.name}</Table.Cell>
            <Table.Cell width="1">{item.rowCount.toLocaleString()}</Table.Cell>
            <Table.Cell width="1">{toFileSize(item.size)}</Table.Cell>
            <Table.Cell width="2">{item.exchangeState.descriptor}</Table.Cell>
            <Table.Cell width="2">{item.createUser}</Table.Cell>
            <Table.Cell className="nowrap" width="1">
              {new Date(item.createDate).toLocaleString("en-GB")}
            </Table.Cell>
          </Table.Row>
        );
      });
    }

    render() {
      const { filters, loading, payload } = this.state;

      return (
        <div>
          {loading === true ? (
            <Loader active />
          ) : (
            <Grid columns={2} style={{ padding: "40px 24px" }}>
              <Grid.Row>
                <Grid.Column computer={3} widescreen={2}>
                  <ExchangeFilter
                    className="item-list"
                    hide={false}
                    items={this.getFilter("CreateUser")}
                    onClick={this.filter.bind(this)}
                    title="Import Authors"
                  />
                  <br />
                  <br />
                  <ExchangeFilter
                    className="item-list"
                    hide={false}
                    items={this.getFilter("ClientItemId")}
                    onClick={this.filter.bind(this)}
                    title="Import Templates"
                  />
                  {filters.length > 0 ? (
                    <div>
                      <h4 style={{ marginTop: "36px" }}>Applied Filters</h4>
                      <ul className="item-list">
                        {filters.map((filter, index) => {
                          return (
                            <li
                              key={index}
                              onClick={this.removeFilter.bind(this, index)}
                            >
                              <Icon name="remove" /> {filter.Text}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  ) : (
                    <div />
                  )}
                </Grid.Column>
                <Grid.Column computer={13} widescreen={14}>
                  <Button.Group style={{ float: "right" }}>
                    <UploadModal
                      key="upload"
                      trigger={
                        <Button
                          className="primary"
                          disabled={!isAuthenticated()}
                          type="button"
                        >
                          <Icon name="upload" />
                          Upload
                        </Button>
                      }
                    />
                    <Button
                      icon="refresh"
                      onClick={this.reload}
                      title="refresh"
                    />
                  </Button.Group>
                  <Header as="h2" className="clear-top">
                    <Icon name="cloud upload" />
                    <Header.Content>
                      DATA EXCHANGE
                      <Header.Subheader>
                        Listing <strong>{payload.client.name}</strong> data sync
                        and transfer files.
                      </Header.Subheader>
                    </Header.Content>
                  </Header>
                  <ExchangeFilter
                    className="tile-list"
                    items={this.getFilter("ExchangeStateId")}
                    onClick={this.filter.bind(this)}
                  />
                  <h4>
                    {filters.length > 0 ? "Filtered" : "Recent"} Transfer
                    Requests ({payload.exchange.length})
                  </h4>
                  <Table celled>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell colSpan="2">File</Table.HeaderCell>
                        <Table.HeaderCell>Template</Table.HeaderCell>
                        <Table.HeaderCell>Rows</Table.HeaderCell>
                        <Table.HeaderCell>Size</Table.HeaderCell>
                        <Table.HeaderCell>Status</Table.HeaderCell>
                        <Table.HeaderCell>Requester</Table.HeaderCell>
                        <Table.HeaderCell>Created</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>{this.tableRow(payload.exchange)}</Table.Body>
                    <Table.Footer>
                      <Table.Row>
                        <Table.HeaderCell colSpan="8">
                          <Menu floated="right" pagination>
                            <Menu.Item as="a" icon>
                              <Icon name="chevron left" />
                            </Menu.Item>
                            <Menu.Item as="a">1</Menu.Item>
                            <Menu.Item as="a">2</Menu.Item>
                            <Menu.Item as="a">3</Menu.Item>
                            <Menu.Item as="a">4</Menu.Item>
                            <Menu.Item as="a" icon>
                              <Icon name="chevron right" />
                            </Menu.Item>
                          </Menu>
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Footer>
                  </Table>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          )}
        </div>
      );
    }
  }
);

import React from "react";
import { Link } from "react-router-dom";

// mobx
import { observable } from "mobx";

// markdown parsing
import frontmatter from "front-matter";
import Markdown from "react-markdown/with-html";
import { Image } from "semantic-ui-react";

export const fragments = observable({});

loadHtml(
  "/custom-content/content-fragments/GettingStarted.md",
  "GettingStarted"
);
loadHtml("/custom-content/content-fragments/Home.md", "Home");
loadHtml("/custom-content/content-fragments/APIs.md", "APIs");
loadHtml("/custom-content/content-fragments/TwilioApi.md", "TwilioApi");
loadHtml("/custom-content/content-fragments/NeedHelp.md", "NeedHelp");

function loadHtml(path, fragment) {
  // loading indicator would go here
  fragments[fragment] = { jsx: () => null };

  fetch(path).then(response =>
    response.text().then(text => {
      let parsedMarkdown = frontmatter(text);

      fragments[fragment] = {
        jsx: () => (
          <Markdown
            escapeHtml={false}
            source={parsedMarkdown.body}
            renderers={renderers}
          />
        ),
        ...parsedMarkdown.attributes
      };
    })
  );
}

const renderers = {
  link: ({ href, ...props }) => {
    // if absolute url, use an `a` tag https://stackoverflow.com/a/19709846/4060061
    if (/^(?:[a-z]+:)?\/\//i.test(href))
      return (
        <a href={href} target="_blank" rel="noopener noreferrer" {...props} />
      );

    return <Link to={href} {...props} />;
  },
  image: ({ src, ...props }) => {
    return <Image src={src} {...props} style={{ maxWidth: "100%" }} />;
  }
};
